import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';


@Component({
    selector: 'app-cvx-navbar',
    standalone: true,
    imports: [CommonModule, MenubarModule],
    templateUrl: './cvx-navbar.component.html',
    styleUrl: './cvx-navbar.component.scss'
})
export class CvxNavbarComponent {

    pMenuItems = input<MenuItem[] | undefined>([]);

}
