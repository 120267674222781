<aside class="cvx-slide-panel">
  <div class="cvx-aside-left-title">
    <span> Hierarchy </span>
  </div>

  <div class="cvx-aside-left-content">
    <p-tree
      [value]="pDataHierarchy()"
      class="w-full md:w-30rem"
      [filter]="true"
      filterMode="strict"
      filterPlaceholder="Hierarchy Filter"
      selectionMode="single"
      emptyMessage="No data hierarchy"
      [(selection)]="selectedDataHierarchy"
      (onNodeExpand)="nodeExpand($event)"
      (onNodeCollapse)="nodeCollapse($event)"
      (onNodeSelect)="nodeSelect($event)"
      (onNodeUnselect)="nodeUnselect($event)"
    />

    <!-- <span>
      <button mat-icon-button (click)="expandAll()">
        <mat-icon>add</mat-icon>
      </button>

      <button mat-icon-button (click)="collapseAll()">
        <mat-icon>remove</mat-icon>
      </button>
    </span> -->
  </div>
</aside>
