<app-cvx-header
    [pTitle]="APP_CONFIG.appTitle"
    [pFullName]="profileName()"
    [pSRCImageProfileUser]="imagenUrl()"
    (btnToggle)="asideLeftPanel.togglePanel()"
/>

<app-cvx-navbar 
    [pMenuItems]="navbarMenuItems()"/>

<app-cvx-main />

<app-cvx-aside-left
    #asideLeftPanel
    [pExpanded]="false"
    [pDataHierarchy]="dataHierarchy()"
/>

<!-- <app-cvx-aside-right /> -->

<app-cvx-footer [pLastSync]="lastSync()" />
