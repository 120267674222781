<nav>
    <div class="cvx-navbar-wrapper">
        <!-- <div class="cvx-navbar-logo"><a href="#">Logo</a></div> -->
        <input type="radio" name="slider" id="menu-btn" />
        <input type="radio" name="slider" id="close-btn" />
        <ul class="cvx-navbar-nav-links">
            <label for="close-btn" class="cvx-navbar-btn cvx-navbar-close-btn">
                <i class="pi pi-times"></i>
            </label>

            @for (item of pMenuItems(); track $index) {
            <li>

                @if (item['subMenu'] || item['megaMenu']) {
                    <a class="cvx-navbar-disabled-link">{{ item['label'] }}</a>
                }
                @else {
                    <a routerLink="{{ item['link'] }}">{{ item['label'] }}</a>
                }


                @if (item['subMenu']) {
                <input type="checkbox" id="showDrop" />
                <label for="showDrop" class="cvx-navbar-mobile-item">{{ item['label'] }}</label>
                <ul class="cvx-navbar-drop-menu">
                    @for (subItem of item['subMenu']; track subItem) {
                    <li>
                        <a routerLink="{{ subItem['link'] }}">{{ subItem['label'] }}</a>
                    </li>
                    }
                </ul>
                } 
                
                @if (item['megaMenu']) {
                <input type="checkbox" id="showMega" />
                <label for="showMega" class="cvx-navbar-mobile-item">{{ item['label'] }}</label>
                <div class="cvx-navbar-mega-box">
                    <div class="cvx-navbar-content">
                        @for (mega of item['megaMenu']; track mega) {
                        <div class="cvx-navbar-row">
                            <header>{{ mega['title'] }}</header>
                            <ul class="cvx-navbar-mega-links">
                                @for (link of mega['subMenu']; track link) {
                                <li>
                                    <a routerLink="{{ link['link'] }}">{{ link['label'] }}</a>
                                </li>
                                }
                            </ul>
                        </div>
                        }
                    </div>
                </div>
                }
            </li>
            }
        </ul>
        <label for="menu-btn" class="cvx-navbar-btn cvx-navbar-menu-btn">
            <i class="pi pi-bars"></i>
        </label>
    </div>
</nav>
