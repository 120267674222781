import { Component } from '@angular/core';

@Component({
  selector: 'app-server-error-page',
  standalone: true,
  imports: [],
  templateUrl: './server-error-page.component.html',
  styleUrl: './server-error-page.component.scss'
})
export class ServerErrorPageComponent {

}
