import { Component, input, OnInit, signal } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { APP_CONFIG } from '../../constants/app-config.const';

@Component({
    selector: 'app-cvx-footer',
    standalone: true,
    imports: [],
    templateUrl: './cvx-footer.component.html',
    styleUrl: './cvx-footer.component.scss'
})
export class CvxFooterComponent implements OnInit {

    pLastSync = input<string | undefined>('Unknow');

    protected appVersion = signal<string>('0.0.0');
    protected dbVersion = signal<string>('0.0.0');
    protected contentContactURL = signal<string>('https://www.chevron.com');
    protected technicalContactURL = signal<string>('https://www.chevron.com');
    protected environment = signal<string>('');
    protected cvxYear = new Date().getFullYear();

    ngOnInit(): void {

        this.appVersion.set(APP_CONFIG.appVersion);
        this.dbVersion.set(APP_CONFIG.dbVersion);
        this.contentContactURL.set(APP_CONFIG.contentContactURL);
        this.technicalContactURL.set(APP_CONFIG.technicalContactURL);

        if (!environment.production)
            this.environment.set(environment.envName.toUpperCase());

    }
}

