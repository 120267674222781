import { inject, Injectable } from '@angular/core';
import { CalAngularService, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { UserProfileStore } from '../../store/user-profile.store';
import { environment } from '../../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private authService: CalAngularService = inject(CalAngularService);
    private apiService: ApiService = inject(ApiService);
    private userProfileStore = inject(UserProfileStore);

    login() {

        this.userProfileStore.StartLoading();
        this.userProfileStore.Authenticate(false);

        this.authService.userInitiatedSignIn().subscribe({
            next: (user: ICvxClaimsPrincipal) => {

                const loggedIn = user?.isUser;

                if (loggedIn) {

                    this.userProfileStore.SetUserProfile(user);

                    this.getToken(['User.Read.All', environment.audience + '/user_impersonation']);

                } else {
                    this.userProfileStore.EndLoadingWithError('User not yet signed in');
                    console.log('user not yet signed in');
                }

            },
            error: (err) => {
                this.userProfileStore.EndLoadingWithError(err);
            },
            complete: () => {
            },
        });

    }

    private getToken(scopes: string[]) {

        this.authService.getAADToken(scopes, false).subscribe({
            next: (response) => {

                //https://jwt.io/
                const token = response ? response.toString() : '';

                this.userProfileStore.SetToken(token);

                this.userProfileStore.Authenticate(true);

                this.userProfileStore.EndLoading();

                //----------------------------------------------------------------------------------
                let headers = new HttpHeaders({
                    'Authorization': 'Bearer ' + token
                });

                this.apiService.get_all(`https://graph.microsoft.com/v1.0/users/${this.userProfileStore.profile()?.id?.() ?? ''}/photo/$value`,
                    {
                        headers: headers,
                        responseType: 'blob'
                    }
                ).subscribe(data => {
                    let objectURL = URL.createObjectURL(data);
                    this.userProfileStore.SetUserImage(objectURL);
                });
                //----------------------------------------------------------------------------------


                // // const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.token);
                // // headers.set('Access-Control-Allow-Origin', '*');
                // // headers.set('Access-Control-Allow-Headers', 'Origin, X-Requested-With, Content-Type, Accept');
                // // headers.set('Content-Type', 'image/jpeg');
                // // const options = {
                // //     method: "GET",
                // //     mode: "cors",
                // //     //responseType: 'blob', 
                // //     headers: headers,
                // // };

                // // this.httpClient.get(`https://graph.microsoft.com/v1.0/users/${this.currentUserProfile.objectId}/photo/$value`, options).subscribe((data) => {

                // //     debugger;

                // //     console.log(data);
                // //     //     this.homeService.userProfile = data;
                // //     //     console.log("data:", data);
                // //     //     this.event.newEvent({ name: 'profile', userProfile: data });
                // //     //     this.profileLoaded = true;
                // // });
                // //----------------------------------------------------------------------------------

            },
            error: (err) => {
                this.userProfileStore.EndLoadingWithError(err);

                console.log('Unable to create audience token');
                console.log(err);
            },
        });
    }

}


// -----login
// logout
// getToken
// isAuthenticated
// refreshToken
// register (si también manejas el registro de usuarios)
// handleError