import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-cvx-main',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './cvx-main.component.html',
  styleUrl: './cvx-main.component.scss'
})
export class CvxMainComponent {

}
