<footer>
    <span>App Version: {{ appVersion() }}</span>
    <span>DB Version: {{ dbVersion() }}</span>
    <span>©{{ cvxYear }} Chevron Corporation</span>
    <span>Confidential Restricted</span>
    <span>
        Content Contact:
        <a target="_blank" href="{{ contentContactURL() }}">IPSOMax Support</a>
    </span>
    <span>
        Technical Contact:
        <a target="_blank" href="{{ technicalContactURL() }}">IPSOMax App Support</a>
    </span>
    <span>Last Sync: {{ pLastSync() }}</span>

    @if (environment()) {
        <span class="cvx-footer-environment">Env: {{ environment() }}</span>
    }

</footer>
