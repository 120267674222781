<!--https://stackblitz.com/edit/angular-xzbxx4?file=app%2Fbutton-types-example.html-->
<!--https://material.angular.io/components/button/overview-->
<h5>Buttons</h5>
<div>
    <button (click)="show()" mat-flat-button class="cvx-button-primary">Primary</button>
    <button (click)="show()" mat-flat-button class="cvx-button-primary" disabled>Disabled 1</button>
    <button mat-flat-button class="cvx-button-primary" [disabled]="true">Disabled 2</button>

    <!-- 
    <button mat-flat-button color="primary">primary</button>
    <button mat-flat-button color="secondary">secondary</button>
    <button mat-flat-button color="tertiary">tertiary</button>
    <button mat-flat-button color="error">error</button>

    <button mat-flat-button class="cvx-button">success 2</button>
    <button mat-flat-button style="background-color: blueviolet">Basic</button>

    <button mat-flat-button disabled>Disabled</button>
    <a mat-flat-button routerLink="#" target="_blank" color="accent">Link</a>
    <a mat-flat-button routerLink="#" target="_blank" color="warn">Link</a> -->
</div>

<!--https://material.angular.io/components/select/overview-->
<h5>Select (Dropdowns)</h5>
<div>
    <mat-form-field>
        <mat-label>State</mat-label>
        <mat-select [disabled]="false">
            <mat-option>None</mat-option>
            @for (state of states; track state) {
            <mat-option [value]="state">{{ state }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-label>State Disabled</mat-label>
        <mat-select [disabled]="true">
            <mat-option>None</mat-option>
            @for (state of states; track state) {
            <mat-option [value]="state">{{ state }}</mat-option>
            }
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Favorite animal</mat-label>
        <mat-select [formControl]="animalControl" required>
            <mat-option>--</mat-option>
            @for (animal of animals; track animal) {
            <mat-option [value]="animal">{{ animal.name }}</mat-option>
            }
        </mat-select>
        @if (animalControl.hasError('required')) {
        <mat-error>Please choose an animal</mat-error>
        }
        <mat-hint>{{ animalControl.value?.sound }}</mat-hint>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Toppings</mat-label>
        <mat-select [formControl]="toppings" multiple>
            @for (topping of toppingList; track topping) {
            <mat-option [value]="topping">{{ topping }}</mat-option>
            }
        </mat-select>
    </mat-form-field>
</div>

<!--https://material.angular.io/components/checkbox/overview-->
<h5>CheckBox</h5>
<div>
    <section class="example-section">
        <mat-checkbox class="example-margin">Check me!</mat-checkbox>
        <mat-checkbox class="example-margin" [disabled]="true">Disabled</mat-checkbox>
    </section>

    <section class="example-section">
        <span class="example-list-section">
            <mat-checkbox class="example-margin" [checked]="task().completed" [indeterminate]="partiallyComplete()" (change)="update1($event.checked)">
                {{ task().name }}
            </mat-checkbox>
        </span>
        <span class="example-list-section">
            <ul style="list-style-type: none; margin-top: 4px">
                @for (subtask of task().subtasks; track subtask; let i = $index) {
                <li>
                    <mat-checkbox [checked]="subtask.completed" (change)="update1($event.checked, i)">
                        {{ subtask.name }}
                    </mat-checkbox>
                </li>
                }
            </ul>
        </span>
    </section>
</div>

<!--https://material.angular.io/components/radio/overview-->
<h5>RadioButton</h5>
<div>
    <mat-radio-group aria-label="Select an option">
        <mat-radio-button value="1">Option 1</mat-radio-button>
        <mat-radio-button value="2">Option 2</mat-radio-button>
    </mat-radio-group>

    <mat-radio-group aria-label="Select an option" [disabled]="true">
        <mat-radio-button value="1">Option 1</mat-radio-button>
        <mat-radio-button value="2">Option 2</mat-radio-button>
    </mat-radio-group>
</div>

<!--https://material.angular.io/components/input/overview-->
<h5>Input</h5>
<div>
    <mat-form-field class="example-full-width">
        <mat-label>Favorite food</mat-label>
        <input matInput placeholder="Ex. Pizza" value="" />
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Email</mat-label>
        <input type="email" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher" placeholder="Ex. pat@example.com" />
        <mat-hint>Errors appear instantly!</mat-hint>
        @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
        <mat-error>Please enter a valid email address</mat-error>
        } @if (emailFormControl.hasError('required')) {
        <mat-error>
            Email is
            <strong>required</strong>
        </mat-error>
        }
    </mat-form-field>
</div>

<!--https://material.angular.io/components/input/overview-->
<h5>Textarea</h5>
<div>
    <mat-form-field class="example-full-width">
        <mat-label>Leave a comment</mat-label>
        <textarea matInput placeholder="Ex. It makes me feel..."></textarea>
    </mat-form-field>
</div>

<!--https://material.angular.io/components/datepicker/overview-->
<h5>DatePicker</h5>
<div>
    <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <!-- #docregion toggle -->
        <input matInput [matDatepicker]="picker2" />
        <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2></mat-datepicker>
        <!-- #enddocregion toggle -->
    </mat-form-field>

    <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        @if (range.controls.start.hasError('matStartDateInvalid')) {
        <mat-error>Invalid start date</mat-error>
        } @if (range.controls.end.hasError('matEndDateInvalid')) {
        <mat-error>Invalid end date</mat-error>
        }
    </mat-form-field>
    <p>Selected range: {{ range.value | json }}</p>

    <mat-form-field class="example-form-field">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="rangePicker">
            <input matStartDate placeholder="Start date" />
            <input matEndDate placeholder="End date" />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <!-- #docregion date-range-picker-actions -->
        <mat-date-range-picker #rangePicker>
            <mat-date-range-picker-actions>
                <button mat-button matDateRangePickerCancel>Cancel</button>
                <button mat-raised-button matDateRangePickerApply>Apply</button>
            </mat-date-range-picker-actions>
        </mat-date-range-picker>
        <!-- #enddocregion date-range-picker-actions -->
    </mat-form-field>
</div>

<!--https://material.angular.io/components/tabs/overview-->
<h5>Tabs</h5>
<div>
    <mat-tab-group>
        <mat-tab label="First">Content 1</mat-tab>
        <mat-tab label="Second">Content 2</mat-tab>
        <mat-tab label="Third">Content 3</mat-tab>
    </mat-tab-group>

    <div style="width: 600px">
        <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
            <mat-tab label="First">Content 1</mat-tab>
            <mat-tab label="Second">Content 2</mat-tab>
            <mat-tab label="Third">Content 3</mat-tab>
        </mat-tab-group>
    </div>
</div>

<!--https://material.angular.io/components/card/overview-->
<h5>Card</h5>
<div>
    <mat-card appearance="outlined">
        <mat-card-content>Simple card</mat-card-content>
    </mat-card>

    <div style="width: 50%">
        <mat-card appearance="outlined">
            <mat-card-header>
                <mat-card-title>Australian Shepherd</mat-card-title>
                <mat-card-subtitle>Herding group</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions>
                <button mat-button>Learn More</button>
            </mat-card-actions>
        </mat-card>
        <br />
        <mat-card appearance="raised">
            <mat-card-header>
                <mat-card-title>Poodle</mat-card-title>
                <mat-card-subtitle>Non-sporting group</mat-card-subtitle>
            </mat-card-header>
            <mat-card-actions align="end">
                <button mat-button>Learn More</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>

<br />
<br />
<br />
<br />
<h5>XXXXXXXXXXXXX</h5>
<div></div>

<br />
<br />
<br />
<br />
<br />
<br />
<div class="card">
    <p-table #dt [value]="customers" [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [loading]="loading" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0" [globalFilterFields]="['name', 'country.name', 'representative.name', 'status']">
        <ng-template pTemplate="caption">
            <div class="flex justify-content-between">
                <p-button [outlined]="true" icon="pi pi-filter-slash" label="Clear" (onClick)="clear(dt)" />
                <p-iconField iconPosition="left">
                    <p-inputIcon>
                        <i class="pi pi-search"></i>
                    </p-inputIcon>
                    <input pInputText type="text" [(ngModel)]="searchValue" placeholder="Keyboard Search" />
                </p-iconField>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox />
                </th>
                <th pSortableColumn="name" style="min-width: 14rem">
                    <div class="flex justify-content-between align-items-center">
                        Name
                        <p-sortIcon field="name" />
                        <p-columnFilter type="text" field="name" display="menu" class="ml-auto" />
                    </div>
                </th>
                <th pSortableColumn="country.name" style="min-width: 14rem">
                    <div class="flex justify-content-between align-items-center">
                        Country
                        <p-sortIcon field="country.name" />
                        <p-columnFilter type="text" field="country.name" display="menu" class="ml-auto" />
                    </div>
                </th>
                <th pSortableColumn="representative.name" style="min-width: 14rem">
                    <div class="flex justify-content-between align-items-center">
                        Agent
                        <p-sortIcon field="representative.name" />
                        <p-columnFilter field="representative" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="ml-auto">
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">Agent Picker</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="representatives" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                    <ng-template let-option pTemplate="item">
                                        <div class="inline-block vertical-align-middle">
                                            <img [alt]="option.label" src="https://primefaces.org/cdn/primeng/images/demo/avatar/{{ option.image }}" width="24" class="vertical-align-middle" />
                                            <span class="ml-1 mt-1">{{ option.name }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="date" style="min-width: 10rem">
                    <div class="flex justify-content-between align-items-center">
                        Date
                        <p-sortIcon field="date" />
                        <p-columnFilter type="date" field="date" display="menu" class="ml-auto" />
                    </div>
                </th>
                <th pSortableColumn="balance" style="min-width: 10rem">
                    <div class="flex justify-content-between align-items-center">
                        Balance
                        <p-sortIcon field="balance" />
                        <p-columnFilter type="numeric" field="balance" display="menu" currency="USD" class="ml-auto" />
                    </div>
                </th>
                <th pSortableColumn="status" style="min-width: 10rem">
                    <div class="flex justify-content-between align-items-center">
                        Status
                        <p-sortIcon field="status" />
                        <p-columnFilter field="status" matchMode="equals" display="menu" class="ml-auto">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <p-tag [value]="option.label" />
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="activity" style="min-width: 10rem">
                    <div class="flex justify-content-between align-items-center">
                        Activity
                        <p-sortIcon field="activity" />
                        <p-columnFilter field="activity" matchMode="between" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false" class="ml-auto">
                            <ng-template pTemplate="filter" let-filter="filterCallback">
                                <p-slider [(ngModel)]="activityValues" [range]="true" (onSlideEnd)="filter($event.values)" styleClass="m-3"></p-slider>
                                <div class="flex align-items-center justify-content-between px-2">
                                    <span>{{ activityValues[0] }}</span>
                                    <span>{{ activityValues[1] }}</span>
                                </div>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th style="width: 5rem"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customer>
            <tr class="p-selectable-row">
                <td>
                    <p-tableCheckbox [value]="customer" />
                </td>
                <td>
                    <span class="p-column-title">Name</span>
                    {{ customer.name }}
                </td>
                <td>
                    <span class="p-column-title">Country</span>
                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + customer.country.code" style="width: 20px" />
                    <span class="ml-1 vertical-align-middle">{{ customer.country.name }}</span>
                </td>
                <td>
                    <span class="p-column-title">Representative</span>
                    <img [alt]="customer.representative.name" src="https://primefaces.org/cdn/primeng/images/demo/avatar/{{ customer.representative.image }}" width="32" style="vertical-align: middle" />
                    <span class="ml-1 vertical-align-middle">{{ customer.representative.name }}</span>
                </td>
                <td>
                    <span class="p-column-title">Date</span>
                    {{ customer.date | date : 'MM/dd/yyyy' }}
                </td>
                <td>
                    <span class="p-column-title">Balance</span>
                    {{ customer.balance | currency : 'USD' : 'symbol' }}
                </td>
                <td>
                    <span class="p-column-title">Status</span>
                    <p-tag [value]="customer.status" />
                </td>
                <td>
                    <span class="p-column-title">Activity</span>
                    <p-progressBar [value]="customer.activity" [showValue]="false" />
                </td>
                <td style="text-align: center">
                    <p-button severity="secondary" icon="pi pi-cog" />
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">No customers found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<br />
<br />
<br />
<br />
<br />
<br />

<p-button icon="pi pi-check" />
<p-button label="Submit" icon="pi pi-check" />
<p-button label="Submit" icon="pi pi-check" iconPos="right" />
<p-button label="Toggle Dark Mode" />

<br />
<br />

<section>
    <div class="example-label">Basic</div>
    <div class="example-button-row">
        <button mat-button>Basic</button>
        <button mat-button disabled>Disabled</button>
        <a mat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
</section>
<mat-divider></mat-divider>
<section>
    <div class="example-label">Raised</div>
    <div class="example-button-row">
        <button mat-raised-button>Basic</button>
        <button mat-raised-button disabled>Disabled</button>
        <a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
</section>
<mat-divider></mat-divider>
<section>
    <div class="example-label">Stroked</div>
    <div class="example-button-row">
        <button mat-stroked-button>Basic</button>
        <button mat-stroked-button disabled>Disabled</button>
        <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
</section>
<mat-divider></mat-divider>
<section>
    <div class="example-label">Flat</div>
    <div class="example-button-row">
        <button mat-flat-button>Basic</button>
        <button mat-flat-button disabled>Disabled</button>
        <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
</section>
<mat-divider></mat-divider>
<section>
    <div class="example-label">Icon</div>
    <div class="example-button-row">
        <div class="example-flex-container">
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
                <mat-icon>more_vert</mat-icon>
            </button>
            <button mat-icon-button disabled aria-label="Example icon button with a open in new tab icon">
                <mat-icon>open_in_new</mat-icon>
            </button>
        </div>
    </div>
</section>
<mat-divider></mat-divider>
<section>
    <div class="example-label">FAB</div>
    <div class="example-button-row">
        <div class="example-flex-container">
            <div class="example-button-container">
                <button mat-fab aria-label="Example icon button with a delete icon">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
            <div class="example-button-container">
                <button mat-fab disabled aria-label="Example icon button with a heart icon">
                    <mat-icon>favorite</mat-icon>
                </button>
            </div>
        </div>
    </div>
</section>
<mat-divider></mat-divider>
<section>
    <div class="example-label">Mini FAB</div>
    <div class="example-button-row">
        <div class="example-flex-container">
            <div class="example-button-container">
                <button mat-mini-fab aria-label="Example icon button with a menu icon">
                    <mat-icon>menu</mat-icon>
                </button>
            </div>
            <div class="example-button-container">
                <button mat-mini-fab disabled aria-label="Example icon button with a home icon">
                    <mat-icon>home</mat-icon>
                </button>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="example-label">Extended Fab</div>
    <div class="example-button-row">
        <div class="example-flex-container">
            <div class="example-button-container">
                <button mat-fab extended>
                    <mat-icon>favorite</mat-icon>
                    Basic
                </button>
            </div>
            <div class="example-button-container">
                <button mat-fab extended disabled>
                    <mat-icon>favorite</mat-icon>
                    Disabled
                </button>
            </div>
            <div class="example-button-container">
                <a mat-fab extended routerLink=".">
                    <mat-icon>favorite</mat-icon>
                    Link
                </a>
            </div>
        </div>
    </div>
</section>
