<div class="cvx-header-container">
  <div class="cvx-header-menu">
    <div class="cvx-header-menu-item">
      <a mat-stroked-button (click)="btnLeftMenuClick($event)">
        <i class="pi pi-bars"></i>
        <!-- <span>MENU</span> -->
      </a>
    </div>
  </div>

  <div class="cvx-header-application">
    <img src="/images/hallmark.png" alt="Logo" />
    <span>{{ pTitle() }}</span>
  </div>

  <div class="cvx-header-profile">
    <div class="cvx-header-profile-info">
      <span>{{ pFullName() }}</span>
      <img [src]="srcImageUser()" alt="User Image" />
    </div>
  </div>
</div>
