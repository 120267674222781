import { Routes } from '@angular/router';
import { TestComponentsPageComponent } from './pages/test-components-page/test-components-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { UnauthorizedPageComponent } from './pages/unauthorized-page/unauthorized-page.component';
import { PageNotFoundPageComponent } from './pages/page-not-found-page/page-not-found-page.component';
import { ServerErrorPageComponent } from './pages/server-error-page/server-error-page.component';

export const routes: Routes = [
	{ path: '', component: HomePageComponent },
	{ path: 'testcomponents', component: TestComponentsPageComponent },
	{ path: '403', component: UnauthorizedPageComponent },
	{ path: '404', component: PageNotFoundPageComponent },
	{ path: '500', component: ServerErrorPageComponent },
	{ path: '**', redirectTo: '404' }
];
