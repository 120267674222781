import { Component, computed, input, output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-cvx-header',
    standalone: true,
    imports: [MatButtonModule],
    templateUrl: './cvx-header.component.html',
    styleUrl: './cvx-header.component.scss'
})
export class CvxHeaderComponent {

    pTitle = input.required<string>();
    pFullName = input<string>('Unknow');
    pSRCImageProfileUser = input<string>('/images/no-avatar.png');
    btnToggle = output<void>();


    protected srcImageUser = computed(() => {
        if (this.pSRCImageProfileUser())
            return this.pSRCImageProfileUser();
        else
            return '/images/no-avatar.png';
    });

    btnLeftMenuClick(event: Event) {
        this.btnToggle.emit();
    }
}
