import { Component, ElementRef, inject, input, OnInit, signal } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { TreeModule } from 'primeng/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-cvx-aside-left',
    standalone: true,
    imports: [TreeModule, MatIconModule, MatButtonModule],
    templateUrl: './cvx-aside-left.component.html',
    styleUrl: './cvx-aside-left.component.scss'
})
export class CvxAsideLeftComponent implements OnInit {
    private _el: ElementRef = inject(ElementRef);

    pExpanded = input<boolean>(false);
    pDataHierarchy = input<TreeNode[]>([]);

    protected selectedDataHierarchy!: TreeNode;
    protected panelVisible = signal(false);

    ngOnInit() {
        this.panelVisible.set(this.pExpanded());
        this.togglePanel(this.panelVisible(), false);
    }

    togglePanel(expanded?: boolean | null, withTransition: boolean = true) {

        const panel = this._el.nativeElement.querySelector('.cvx-slide-panel');

        if (panel) {
            if (!withTransition) {
                panel.classList.add('cvx-no-transition');
            } else {
                panel.classList.remove('cvx-no-transition');
            }
        }

        if (expanded === undefined || expanded === null) {
            this.panelVisible.set(!this.panelVisible());
        }
        else {
            this.panelVisible.set(expanded);
        }

        if (panel) {
            if (this.panelVisible()) {
                panel.classList.remove('cvx-hidden-content');
            }
            else {
                panel.classList.add('cvx-hidden-content');
            }
        }
    }

    expandAll() {
        this.pDataHierarchy().forEach((node) => {
            this.expandRecursive(node, true);
        });
    }

    collapseAll() {
        this.pDataHierarchy().forEach((node) => {
            this.expandRecursive(node, false);
        });
    }

    private expandRecursive(node: TreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach((childNode) => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    nodeExpand(event: any) {
        //this.messageService.add({ severity: 'success', summary: 'Node Expanded', detail: event.node.label });
    }

    nodeCollapse(event: any) {
        //this.messageService.add({ severity: 'warn', summary: 'Node Collapsed', detail: event.node.label });
    }

    nodeSelect(event: any) {
        //this.messageService.add({ severity: 'info', summary: 'Node Selected', detail: event.node.label });
    }

    nodeUnselect(event: any) {
        //this.messageService.add({ severity: 'info', summary: 'Node Unselected', detail: event.node.label });
    }
}
