import { ApplicationConfig, importProvidersFrom, ImportProvidersSource, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { appProviders } from './app.providers';
import { CalAngularModule } from '@cvx/cal-angular';
import { environment } from '../environments/environment';
import { provideHttpClient } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),

        provideRouter(routes),

        provideHttpClient(),

        provideAnimationsAsync(),

        importProvidersFrom(
            ...appProviders as ImportProvidersSource[],

            //CVX CAL -----------------------------
            CalAngularModule.forRoot({
                "autoSignIn": true,
                "popupForLogin": false,
                "cacheLocation": "localStorage",
                "instance": "https://login.microsoftonline.com/",
                "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26", // This is your tenant ID for Chevron - DON'T TOUCH
                "clientId": environment.clientId,
                // redirectUri will need to match a redirectUri setup in your app registration
                "redirectUri": environment.redirectUri,
                "oidcScopes": ["openid", "profile", "User.Read", "offline_access"],
                "graphScopes": ["User.Read", "User.Read.All"]
            }),
            //-------------------------------------

        )
    ]
};
