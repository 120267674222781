import { Component, computed, inject, OnInit, signal } from '@angular/core';
import { CvxHeaderComponent } from "./shared/components/cvx-header/cvx-header.component";
import { CvxNavbarComponent } from "./shared/components/cvx-navbar/cvx-navbar.component";
import { CvxMainComponent } from "./shared/components/cvx-main/cvx-main.component";
import { CvxAsideLeftComponent } from "./shared/components/cvx-aside-left/cvx-aside-left.component";
import { CvxAsideRightComponent } from "./shared/components/cvx-aside-right/cvx-aside-right.component";
import { CvxFooterComponent } from "./shared/components/cvx-footer/cvx-footer.component";
import { APP_CONFIG } from './shared/constants/app-config.const';
import { MenuItem, TreeNode } from 'primeng/api';
import { UserProfileStore } from './shared/store/user-profile.store';
import { AuthService } from './shared/services/auth/auth.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CvxHeaderComponent, CvxNavbarComponent, CvxMainComponent, CvxAsideLeftComponent, CvxAsideRightComponent, CvxFooterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    private userProfileStore = inject(UserProfileStore);
    private authService: AuthService = inject(AuthService);

    protected APP_CONFIG: any = APP_CONFIG;
    protected lastSync = signal<string>('2024-10-25');
    protected profileName = computed(() => this.userProfileStore.profile()?.fullName?.() ?? '');
    protected imagenUrl = computed(() => this.userProfileStore.profile()?.imageURL?.() ?? '');

    protected dataHierarchy = signal<TreeNode[]>([
        {
            key: '0',
            label: 'Documents',
            data: 'Documents Folder',
            icon: 'pi pi-fw pi-inbox',
            children: [
                {
                    key: '0-0',
                    label: 'Work',
                    data: 'Work Folder',
                    icon: 'pi pi-fw pi-cog',
                    children: [
                        { key: '0-0-0', label: 'Expenses.doc', icon: 'pi pi-fw pi-file', data: 'Expenses Document' },
                        { key: '0-0-1', label: 'Resume.doc', icon: 'pi pi-fw pi-file', data: 'Resume Document' }
                    ]
                },
                {
                    key: '0-1',
                    label: 'Home',
                    data: 'Home Folder',
                    icon: 'pi pi-fw pi-home',
                    children: [{ key: '0-1-0', label: 'Invoices.txt', icon: 'pi pi-fw pi-file', data: 'Invoices for this month' }]
                }
            ]
        },
        {
            key: '1',
            label: 'Events',
            data: 'Events Folder',
            icon: 'pi pi-fw pi-calendar',
            children: [
                { key: '1-0', label: 'Meeting', icon: 'pi pi-fw pi-calendar-plus', data: 'Meeting' },
                { key: '1-1', label: 'Product Launch', icon: 'pi pi-fw pi-calendar-plus', data: 'Product Launch' },
                { key: '1-2', label: 'Report Review', icon: 'pi pi-fw pi-calendar-plus', data: 'Report Review' }
            ]
        },
        {
            key: '2',
            label: 'Movies',
            data: 'Movies Folder',
            icon: 'pi pi-fw pi-star-fill',
            children: [
                {
                    key: '2-0',
                    icon: 'pi pi-fw pi-star-fill',
                    label: 'Al Pacino',
                    data: 'Pacino Movies',
                    children: [
                        { key: '2-0-0', label: 'Scarface', icon: 'pi pi-fw pi-video', data: 'Scarface Movie' },
                        { key: '2-0-1', label: 'Serpico', icon: 'pi pi-fw pi-video', data: 'Serpico Movie' }
                    ]
                },
                {
                    key: '2-1',
                    label: 'Robert De Niro',
                    icon: 'pi pi-fw pi-star-fill',
                    data: 'De Niro Movies',
                    children: [
                        { key: '2-1-0', label: 'Goodfellas', icon: 'pi pi-fw pi-video', data: 'Goodfellas Movie' },
                        { key: '2-1-1', label: 'Untouchables', icon: 'pi pi-fw pi-video', data: 'Untouchables Movie', selectable: false }
                    ]
                }
            ]
        }
    ]);
    protected navbarMenuItems = signal<MenuItem[] | undefined>([
        { "label": "Home", "link": "" },
        { "label": "Landing", "link": "" },
        { "label": "Utilization", "link": "/testcomponents" },
        { "label": "Admin", "link": "#" },

        // {
        //     "label": "Dropdown Menu A",
        //     "link": "#",
        //     "subMenu": [
        //         { "label": "Drop menu 1", "link": "#" },
        //         { "label": "Drop menu 2", "link": "#" },
        //         { "label": "Drop menu 3", "link": "#" },
        //         { "label": "Drop menu 4", "link": "#" }
        //     ]
        // },

        // {
        //     "label": "Mega Menu A",
        //     "link": "#",
        //     "megaMenu": [
        //         {
        //             "title": "Title 1",
        //             "subMenu": [
        //                 { "label": "Drop menu 1-1", "link": "#" },
        //                 { "label": "Drop menu 1-2", "link": "#" },
        //                 { "label": "Drop menu 1-3", "link": "#" },
        //                 { "label": "Drop menu 1-4", "link": "#" }
        //             ]
        //         },
        //         {
        //             "title": "Title 2",
        //             "subMenu": [
        //                 { "label": "Drop menu 2-1", "link": "#" },
        //                 { "label": "Drop menu 2-2", "link": "#" },
        //                 { "label": "Drop menu 2-3", "link": "#" },
        //                 { "label": "Drop menu 2-4", "link": "#" }
        //             ]
        //         }
        //     ]
        // }
    ]);


    constructor() {
        this.authService.login();
    }

    ngOnInit(): void {
    }

}
