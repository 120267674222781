import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { UserProfileState } from "../models/store/user-profile-state.model";
import { computed } from '@angular/core';
import { ICvxClaimsPrincipal } from '@cvx/cal-angular';

const initialState: UserProfileState = {
	userProfile: {
		id: '',
		fullName: '',
		email: '',
		imageURL: ''
	},
	token: '',
	isAuthenticated: false,
	isLoading: false,
	errorMessage: ''
};

export const UserProfileStore = signalStore(
	{ providedIn: 'root' },
	withState(initialState),
	withComputed(({ userProfile, token, isAuthenticated, isLoading, errorMessage }) => ({
		profile: computed(() => userProfile || initialState.userProfile),

		// NO SE PUEDE HACER MAS UN OVERRIDE DE UNA VARIABLE, DE POR SI YA ESTAN PUBLICAS CON ESOS NOMBRES. -------
		// token: computed(() => token),
		// isAuthenticated: computed(() => isAuthenticated),
		// isLoading: computed(() => isLoading),
		// errorMessage: computed(() => errorMessage),
		// --------------------------------------------------------------------------------------------------------
	})),
	withMethods((store
		//, _authService = inject(AuthService)
	) => ({

		StartLoading: () => {
			patchState(store, { isLoading: true, errorMessage: '' });
		},

		EndLoading: () => {
			patchState(store, { isLoading: false });
		},

		EndLoadingWithError: (error: string) => {
			patchState(store, { isLoading: false, errorMessage: error });
		},

		Authenticate: (isAuthenticated: boolean) => {
			patchState(store, { isAuthenticated: isAuthenticated });
		},

		SetUserProfile: (userProfileCvx: ICvxClaimsPrincipal) => {

			patchState(store, {
				userProfile: {
					...store.userProfile(),
					id: userProfileCvx.objectId || '',
					fullName: userProfileCvx.name || '',
					email: userProfileCvx.email || ''
				}
			});

		},

		SetToken: (token: string) => {
			patchState(store, { token: token });
		},

		SetUserImage: (imageUrl: string) => {

			patchState(store, {
				userProfile: {
					...store.userProfile(),
					imageURL: imageUrl || ''
				}
			});

		},

		// LogIn: () => {			
		// 	patchState(store, { isLoading: true, errorMessage: '' });
		// 	_authService.login();
		// },

		// loadToken: () => {
		// 	patchState(store, { isLoading: true })
		// }


		// loadToken: () => {
		// 	patchState(store, { isLoading: true });
		// 	authService.getToken().subscribe(
		// 	  (token) => patchState(store, { token, isLoading: false }),
		// 	  (error) => patchState(store, { errorMessage: error.message, isLoading: false })
		// 	);
		//   },
		//   logout: () => {
		// 	authService.logout();
		// 	patchState(store, { token: null });
		//   }


	}))
);
