export const environment = {
    envName: 'dev',
    production: false,

    //https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/3d37e70a-eb88-4feb-a73e-907bdf1a3278/isMSAApp~/false
    clientId: '1800f7fa-f650-4f07-adf8-fec0c9903d67',
    redirectUri: 'https://pricef-dev.azure.chevron.com/',
    audience: "https://pricef-test.azure.chevron.com",

    hostApiURL: '',
    //hostURL: 'https://sjvchk-dev.azure.chevron.com/',
};
