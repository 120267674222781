import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Signal, signal } from '@angular/core';
import { environment } from '../../../environments/environment';
import { catchError, finalize, firstValueFrom, Observable, throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private jsonOptions = {
        headers: new HttpHeaders({
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
        }),
    };

    constructor(private http: HttpClient) { }

    private handleServerError(err: HttpErrorResponse) {

        if (!err.ok) {
            let errdescription = err.message;

            if (err.status == 404) {
                if (err.error && err.error.title)
                    errdescription = err.error.title;
            }
        }

        return throwError(() => err);
    }

    get(path: string): Observable<any> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
            }),
        };

        return this.http
            .get(`${environment.hostApiURL}${path}`, httpOptions)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.handleServerError(err);
                }),
                finalize(() => {
                })
            );
    }

    get_all(path: string, httpOptions: any): Observable<any> {

        return this.http
            .get(`${path}`, httpOptions)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.handleServerError(err);
                }),
                finalize(() => {
                })
            );

    }

    get_mock(path: string): Observable<any> {

        const httpOptions = {};

        return this.http
            .get(`assets/data/${path}`, httpOptions)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.handleServerError(err)
                }),
                finalize(() => {
                })
            );
    }

    get_excel(path: string): Observable<any> {

        // const httpOptions = {
        //   headers: new HttpHeaders({
        //     'Access-Control-Allow-Origin': '*',
        //     'Content-Type': 'application/vnd.ms-excel',
        //   }),
        // };

        return this.http
            .get(`${environment.hostApiURL}${path}`, { responseType: 'blob' })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.handleServerError(err);
                }),
                finalize(() => {
                })
            );
    }

    post(path: string, body: object = {}): Observable<any> {

        return this.http
            .post(
                `${environment.hostApiURL}${path}`,
                JSON.stringify(body),
                this.jsonOptions
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.handleServerError(err);
                }),
                finalize(() => {
                })
            );
    }

    put(path: string, body: any = {}): Observable<any> {

        return this.http
            .put(
                `${environment.hostApiURL}${path}`,
                JSON.stringify(body),
                this.jsonOptions
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.handleServerError(err);
                }),
                finalize(() => {
                })
            );
    }

    delete(path: string, body: any = {}): Observable<any> {

        return this.http
            .delete(`${environment.hostApiURL}${path}`)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.handleServerError(err);
                }),
                finalize(() => {
                })
            );
    }

    async get_sync<T>(path: string): Promise<T> {
        //--------------------------------------------------------------------------
        //CALL: const resultado = await this.apiService.get_sync<CLASS_TYPE>(`api/modelFiles`); 
        //--------------------------------------------------------------------------

        try {
            const response = await firstValueFrom(this.http.get<T>(path));
            return response;
        } catch (error) {
            console.error('Error during synchronous HTTP request:', error);
            throw error;
        }
    }

    async get_mock_sync<T>(path: string): Promise<T> {
        //--------------------------------------------------------------------------
        //CALL: const resultado = await this.apiService.get_mock_sync<CLASS_TYPE>(`modelFiles1.json`); //`../../assets/data/modelFiles1.json`
        //--------------------------------------------------------------------------

        const httpOptions = {};

        try {
            const response = await firstValueFrom(this.http.get<T>(`assets/data/${path}`));
            return response;
        } catch (error) {
            console.error('Error during synchronous HTTP request:', error);
            throw error;
        }
    }



    // private tokenSignal = signal<string | null>(null);
    // private errorSignal = signal<string | null>(null);

    // private jsonOptions = {
    //   headers: new HttpHeaders({
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/json',
    //   }),
    // };

    // constructor(private http: HttpClient) { }

    // getTokenSignal(): Signal<string | null> {
    //   return this.tokenSignal;
    // }

    // getErrorSignal(): Signal<string | null> {
    //   return this.errorSignal;
    // }

    // private handleServerError(error: any): void {
    //   let errorMessage = 'An unknown error occurred!';
    //   if (error instanceof HttpErrorResponse) {
    //     errorMessage = `HTTP error! status: ${error.status}`;
    //   } else if (error.message) {
    //     errorMessage = error.message;
    //   }
    //   this.errorSignal.set(errorMessage);
    // }

    // async get(path: string): Promise<void> {
    //   try {
    //     const response = await fetch(`${environment.hostApiURL}${path}`, {
    //       headers: {
    //         'Access-Control-Allow-Origin': '*',
    //       }
    //     });

    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }

    //     const data = await response.json();
    //     this.tokenSignal.set(data.token);
    //   } catch (error) {
    //     this.handleServerError(error);
    //   }
    // }

    // async get_mock(path: string): Promise<void> {
    //   try {
    //     const response = await fetch(`assets/data/${path}`);
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    //     const data = await response.json();
    //     this.tokenSignal.set(data.token);
    //   } catch (error) {
    //     this.handleServerError(error);
    //   }
    // }

    // async get_excel(path: string): Promise<void> {
    //   try {
    //     const response = await fetch(`${environment.hostApiURL}${path}`, {
    //       headers: {
    //         'Access-Control-Allow-Origin': '*',
    //       }
    //     });

    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }

    //     const blob = await response.blob();
    //     // Manejar la respuesta del blob aquí si es necesario
    //   } catch (error) {
    //     this.handleServerError(error);
    //   }
    // }

    // async post(path: string, body: object = {}): Promise<void> {
    //   try {
    //     const response = await fetch(`${environment.hostApiURL}${path}`, {
    //       method: 'POST',
    //       headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(body)
    //     });

    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }

    //     const data = await response.json();
    //     this.tokenSignal.set(data.token);
    //   } catch (error) {
    //     this.handleServerError(error);
    //   }
    // }

    // async put(path: string, body: any = {}): Promise<void> {
    //   try {
    //     const response = await fetch(`${environment.hostApiURL}${path}`, {
    //       method: 'PUT',
    //       headers: {
    //         'Access-Control-Allow-Origin': '*',
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(body)
    //     });

    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }

    //     const data = await response.json();
    //     this.tokenSignal.set(data.token);
    //   } catch (error) {
    //     this.handleServerError(error);
    //   }
    // }

    // async delete(path: string): Promise<void> {
    //   try {
    //     const response = await fetch(`${environment.hostApiURL}${path}`, {
    //       method: 'DELETE',
    //       headers: {
    //         'Access-Control-Allow-Origin': '*',
    //       }
    //     });

    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }

    //     const data = await response.json();
    //     this.tokenSignal.set(data.token);
    //   } catch (error) {
    //     this.handleServerError(error);
    //   }
    // }

}


//----------------------------------------------------------------
// //LOAD DATA FROM MEMORY-------------------------------------------
// //-METODO 1-------------------------------------------------------
// const httpOptions = {
//     headers: new HttpHeaders({
//         'Access-Control-Allow-Origin': '*',
//     }),
// };

// const x = this.http.get(`api/modelFiles`, httpOptions).pipe(
//     catchError((err: HttpErrorResponse) => {
//         return throwError(err).toPromise();
//     }),
//     finalize(() => {})
// );

// const sub = x.subscribe({
//     next: (data: any) => {
//         var s = 0;
//         //debugger;
//         sub.unsubscribe();
//     },
// });
// //----------------------------------------------------------------
// //-METODO 2-------------------------------------------------------
// let errorMessage: string = '';
// const resultado = this.http
//     .get<IModelFile[]>(`api/modelFiles`)
//     .pipe()
//     .subscribe({
//         next: (modelfiles) => {
//             //debugger;
//         },
//         error: (error) => (errorMessage = error),
//     });
// //----------------------------------------------------------------
// //-METODO 3-------------------------------------------------------
// //debugger;
// const resultado2 = await this.apiService.get_sync(`api/modelFiles`);
// //----------------------------------------------------------------
